import pluralize from 'pluralize'
import { DateTime } from 'luxon'
import { find, remove } from 'lodash'
import { Sec } from '@/mixins/sec'

export default {
  clearData (data) {
    for (const key in data) {
      if (key === 'id') delete data[key]
      else if (data[key] instanceof File) delete data[key]
      else if (data[key] !== null && typeof (data[key]) === 'object') {
        this.clearData(data[key])
      } else {
        data[key] = null
      }
    }
  },
  copyData (from, to) {
    this.clearData(to)
    if (to !== null && typeof to === 'object') {
      for (const key in to) {
        if (to[key] !== null && typeof (to[key]) === 'object' && from[key] && from[key] !== null) {
          this.copyData(from[key], to[key])
        } else if (from[key] && from[key] !== null) {
          to[key] = from[key]
        }
      }
    } else {
      to = from
    }
  },
  firstToLower (string) {
    return string.charAt(0).toLowerCase() + string.slice(1)
  },
  firstToUpper (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  },
  camelToKebab (string) {
    return string.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
  },
  camelToString (string) {
    return string.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase()
  },
  kebabToCamel (string) {
    if (!string) return null
    return string.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase()
    })
  },
  kebabToSnake (string) {
    return string.replace(/-/g, '_').toLowerCase()
  },
  camelToSpace (string) {
    return string.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase()
  },
  camelToSnake (string) {
    return string.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()
  },
  snakeToCamel (string) {
    return string.replace(/(_\w)/g, function (m) {
      return m[1].toUpperCase()
    })
  },
  spaceToPlus (string) {
    return string.replace(/\s/g, '+')
  },
  parseURL (string) {
    return new URL(string)
  },
  randStr (length) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  },
  key () {
    return this.randStr(8)
  },
  pluralize,
  singular (string) {
    return pluralize.singular(string)
  },
  base64ToFile (base64, filename) {
    const arr = base64.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  },
  secondsToTime (seconds) {
    const date = new Date(0)
    date.setSeconds(seconds)
    return date.toISOString().substr(11, 8)
  },
  secondsToHumanTime (seconds) {
    const date = new Date(0)
    date.setSeconds(seconds)
    let hours = date.toISOString().substr(11, 2)
    if (parseInt(hours) > 0) hours = hours + ' Hours'
    else hours = ''
    let minutes = date.toISOString().substr(14, 2)
    if (parseInt(minutes) > 0) minutes = minutes + ' Minutes'
    else minutes = ''
    return hours + ' ' + minutes
  },
  dtFull (iso) {
    return DateTime.fromISO(iso).toFormat('LLL d, yyyy h:mm a')
  },
  dt12Time (iso) {
    return DateTime.fromISO(iso).toFormat('h:mm a')
  },
  find (objects, match, value, key) {
    const obj = find(objects, function (o) { return o[match] === value })
    if (obj && obj[key]) return obj[key]
    return null
  }
}

export function clearData (data) {
  for (const key in data) {
    if (data[key] instanceof File) delete data[key]
    else if (data[key] !== null && typeof (data[key]) === 'object') {
      clearData(data[key])
    } else {
      data[key] = null
    }
  }
}

export function fillObject (target, source, delay = 0) {
  return new Promise(resolve => {
    setTimeout(() => {
      Object.keys(target).forEach(key => {
        if (source && key in source) target[key] = source[key]
      })
      return resolve()
    }, delay)
  })
}

export function pluck (collection) {
  return {
    using: (value) => {
      return {
        display: (key) => {
          const obj = find(collection, o => o.id === value)
          if (obj) return obj[key]
          return null
        },
        obj: () => {
          return find(collection, o => o.id === value)
        }
      }
    }
  }
}

export function kebabToCamel (string) {
  if (typeof string !== 'string') return string
  return string.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase()
  })
}

export function kebabToSnake (string) {
  if (typeof string !== 'string') return string
  return string.replace(/-/g, '_').toLowerCase()
}

export function camelToSnake (string) {
  if (typeof string !== 'string') return string
  return string.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()
}

export function camelToKebab (string) {
  return typeof string === 'string' ? string.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() : string
}

export function snakeToCamel (string) {
  if (typeof string !== 'string') return string
  return string.replace(/(_\w)/g, function (m) {
    return m[1].toUpperCase()
  })
}

export function timeDisplay (time, format) {
  const dt = new Sec(time)
  if (format === 12) return dt.format('g:ia')
  else return dt.format('G:i')
}

export function ucFirst (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function slug (string) {
  return string.toLowerCase().replace(/[^a-z0-9]+/g, '-')
}

export function firstToUpper (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function replaceOrPush (array, object) {
  const index = array.findIndex((item) => item.id === object.id)
  if (index === -1) {
    array.push(object)
  } else {
    array = [
      ...array.slice(0, index),
      object,
      ...array.slice(index + 1)
    ]
  }
  return array
}

export function merge (original, data) {
  return new Promise(resolve => {
    data.forEach(o => {
      remove(original, i => i.id === o.id)
      original.push(o)
    })
    resolve()
  })
}

export function singular (string) {
  if (typeof string !== 'string') return string
  return pluralize.singular(string)
}

export function validate (resource, rules) {
  let valid = true
  rules.forEach(f => {
    if (!resource[f]) valid = false
  })
  return valid
}

export function validateEmail (email) {
  if (!email) return false
  if (email.includes('unknown')) return false
  if (email.includes('jwpub.org')) return false
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
  // return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}
