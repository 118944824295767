<template>

  <view-content :back="{name:'Settings'}">

    <template v-slot:header>
      Assign Maps
    </template>

    <collapse accordion is-fullwidth>
      <collapse-item title="Order & Filter">
        <div class="field has-addons">
          <p class="control">
            <button class="button" :class="{'is-info':$store.state.core.order.map_assignments[0] === 'order'}" @click="setOrder('order')">
              <fa-icon :icon="['fal', 'arrow-alt-down']" v-if="($store.state.core.order.map_assignments[0] === 'order' && $store.state.core.order.map_assignments[1] === 'asc') || $store.state.core.order.map_assignments[0] !== 'order'" />
              <fa-icon :icon="['fal', 'arrow-alt-up']" v-if="$store.state.core.order.map_assignments[0] === 'order' && $store.state.core.order.map_assignments[1] === 'desc'" />
              <span>Map Number</span>
            </button>
          </p>
          <p class="control">
            <button class="button" :class="{'is-info':$store.state.core.order.map_assignments[0] === 'last_completed'}" @click="setOrder('last_completed')">
              <fa-icon :icon="['fal', 'arrow-alt-down']" v-if="($store.state.core.order.map_assignments[0] === 'last_completed' && $store.state.core.order.map_assignments[1] === 'asc') || $store.state.core.order.map_assignments[0] !== 'last_completed'" />
              <fa-icon :icon="['fal', 'arrow-alt-up']" v-if="$store.state.core.order.map_assignments[0] === 'last_completed' && $store.state.core.order.map_assignments[1] === 'desc'" />
              <span>Last Complete</span>
            </button>
          </p>
        </div>
        <div class="field has-addons">
          <p class="control">
            <button class="button" :class="{'is-info':$store.state.core.filters.map_assignments.findIndex(row => row.name === 'assignment') >= 0 }" @click="setFilter('assignment',true)">
              <fa-icon :icon="['fal', 'filter']" />
              <span>Assigned</span>
            </button>
          </p>
        </div>
      </collapse-item>
    </collapse>

    <div class="card is-blue" v-for="map in orderedMaps" :key="'map' + map.id">
      <div class="card-content">
        <div class="number-header">
          <router-link :to="'/map/'+map.number+'/'" class="num" style="color:#20292f">
            <span>{{ map.number }}</span>
          </router-link>
          <div class="header">
            {{ map.suburb }}
          </div>
        </div>
        <table class="table is-narrow is-fullwidth no-border">
          <tr v-if="map.assignment">
            <th width="120">Owner:</th>
            <td v-if="map.assignment.user">{{ map.assignment.user.full_name }} <small v-if="map.assignment.personal">(Personal Map)</small></td>
            <td v-if="!map.assignment.user">No Current Owner</td>
          </tr>
          <tr v-if="map.assignment">
            <th width="100px">Time Out:</th>
            <td>{{ map.assignment.time_out }}</td>
          </tr>
          <tr v-if="map.assignment">
            <th width="100px">Completed:</th>
            <td>{{ map.assignment.completed }}</td>
          </tr>
          <tr v-if="map.address_count > 0">
            <th width="100px">Addresses:</th>
            <td>{{ map.address_count }} <small v-if="map.number_lookup">({{ map.searched_address_count }} searched)</small></td>
          </tr>
          <tr v-if="!map.assignment">
            <th width="120">Last Complete:</th>
            <td>{{ ago(map.last_completed) }}</td>
          </tr>
          <tr v-if="map.campaign">
            <th>Campaign:</th>
            <td>{{ map.campaign }}</td>
          </tr>
        </table>
      </div>
      <div class="card-footer" v-if="permits['settings.maps.assign']">
        <div class="tools">
          <button @click="lockMap(map)" class="btn btn-warning btn-sm ml-1 mb-1" v-if="!map.locked && !map.assignment">
            <fa-icon :icon="['fal', 'unlock']" /> Lock
          </button>
          <button @click="lockMap(map)" class="btn btn-danger btn-sm ml-1 mb-1" v-if="map.locked">
            <fa-icon :icon="['fal', 'lock']" /> Unlock
          </button>
          <button @click="newAssignment(map)" class="btn btn-success btn-sm ml-1 mb-1" v-if="!map.assignment && !map.locked">
            <fa-icon :icon="['fal', 'inbox-out']" /> Assign
          </button>
          <button @click="promptCancelMap(map)" class="btn btn-danger btn-sm ml-1 mb-1" v-if="map.assignment && (!map.assignment.started || map.type_id === 3 || map.type_id === 4 || map.type_id === 5)">
            <fa-icon :icon="['fal', 'ban']" /> Cancel
          </button>
          <button v-show="false" @click="toggleNumbers(map)" class="btn btn-sm ml-1 mb-1" :class="map.number_lookup ? 'btn-success' : 'btn-default'" v-if="map.assignment && map.address_count > 0">
            <fa-icon :icon="['fal', 'phone-office']" /> Number Lookup
          </button>
          <button @click="promptRecallMap(map)" class="btn btn-warning btn-sm ml-1 mb-1" v-if="map.assignment">
            <fa-icon :icon="['fal', 'inbox-in']" /> Recall
          </button>
          <button @click="reAssignment(map)" class="btn btn-info btn-sm ml-1 mb-1" v-if="map.assignment">
            <fa-icon :icon="['fal', 'inbox-out']" /> Reassign
          </button>
          <button @click="reAssignment(map)" class="btn btn-info btn-sm ml-1 mb-1" v-if="false">
            <fa-icon :icon="['fal', 'books']" /> Records
          </button>
        </div>
      </div>
    </div>

    <portal to="modals">
    <modal :submitting="submitting" :header="'Assign Map '+map.number" :active.sync="assignFormActive" @submit="submitAssignment()" :validated="assignmentValid">
      <input-toggle class="mb-3" :label="'Personal Map'" v-model="map.personal" />
      <tuxedo-select
        v-if="(map.type_id != 4 && map.type_id != 5) || map.personal"
        :label="'Assign To'"
        name="user"
        :options="activeUsers"
        v-model="map.user"
        insistLabel="last_first"
        inputClass="bg-gray-100"
        insistReturn="id"
      />
      <input-slider :label="'Special Campaign'" v-model="map.campaign" :options="campaigns" />
      <input-text v-if="parseInt(map.campaign) === 3" :label="'Campaign Name'" v-model="map.custom" />
    </modal>

    <modal :submitting="submitting" :header="'Reassign Map '+map.number" :active.sync="reAssignFormActive" @submit="submitReAssignment()" :validated="assignmentValid">
      <input-toggle class="mb-3" :label="'Personal Map'" v-model="map.personal" />
      <tuxedo-select
        :label="'Assign To'"
        name="user"
        :options="activeUsers"
        v-model="map.user"
        insistLabel="last_first"
        inputClass="bg-gray-100"
        insistReturn="id"
      />
    </modal>

    <update
      :submitting="submitting"
      :header="'Recall Map '+map.number"
      :active.sync="mapRecallActive"
      :message="'Recalling this map will clear all available territory attached and finalise this maps current assignment. Are you sure you wish to continue?'"
      @submit="confirmRecallMap()"
    ></update>
    <update
      :submitting="submitting"
      :header="'Cancel Assignment '+map.number"
      :active.sync="mapCancelActive"
      :message="'Cancelling this maps current assignment will return the map back to its previous completed assignment. Are you sure you wish to continue?'"
      @submit="confirmCancelMap()"
    ></update>

    <progress-modal :active="progressActive" :progress="progress.percent">
      <p>Populating addresses (<animated-number :value="progress.complete" :formatValue="formatToInteger" :duration="3600"/> of {{ progress.total }}) and removing any Do Not Calls, this may take a few minutes.</p>
    </progress-modal>

    </portal>
  </view-content>

</template>

<script>

import { mapStores } from 'pinia'
import { useCollectionsStore } from '@/stores/collections'
import axios from 'axios'
import Update from '../../general/confirm-update.vue'
import Collapse from 'vue-bulma-collapse/src/Collapse.vue'
import CollapseItem from 'vue-bulma-collapse/src/Item.vue'
import AnimatedNumber from 'animated-number-vue'

import { mapState } from 'vuex'
import { Sec } from '@/mixins/sec'

export default {
  name: 'Assign',
  data () {
    return {
      map: {
        id: null,
        number: null,
        personal: null,
        user: null,
        campaign: 0,
        custom: null,
        assignment: null,
        type_id: null
      },
      campaigns: ['None', 'Memorial', 'Convention', 'Custom'],
      assignFormActive: false,
      reAssignFormActive: false,
      mapRecallActive: false,
      mapCancelActive: false,
      submitting: false,
      loading: true,
      error: false,
      progressActive: false,
      progress: {
        percent: 0,
        total: 0,
        complete: 0
      }
    }
  },
  computed: {
    ...mapStores(useCollectionsStore),
    ...mapState({
      permits: state => state.core.permits,
      maps: state => state.maps.maps
    }),
    activeUsers: function () {
      const pubs = this.collectionsStore.users.filter(pub => pub.active && ['guest', 'unbaptised', 'publisher', 'servant', 'elder'].includes(pub.appointment))
      return this._.orderBy(pubs, ['last_first'], ['asc'])
    },
    assignmentValid () {
      return true
      // if (this.map.user || this.map.type_id === 3 || this.map.type_id === 4 || this.map.type_id === 5) { return true } else { return false }
    },
    orderedMaps: function () {
      let maps = this._.orderBy(this.maps, [this.$store.state.core.order.map_assignments[0]], [this.$store.state.core.order.map_assignments[1]])
      if (this.$store.state.core.filters.map_assignments.length > 0) {
        this.$store.state.core.filters.map_assignments.forEach(filter => {
          maps = maps.filter(record => {
            if (filter.value === true && record[filter.name]) {
              return record
            }
          })
        })
      }
      return maps
    }
  },
  components: {
    Update, Collapse, CollapseItem, AnimatedNumber
  },
  created () {
    window.scrollTo(0, 0)
    this.$store.commit('BACKGROUND_LOADING', true)
    this.$store.dispatch('GET_MAPS', { token: this.$store.state.core.token }).then(() => {
      this.$store.commit('BACKGROUND_LOADING', false)
    })
  },
  methods: {
    formatToInteger (value) {
      return parseInt(value)
    },
    lockMap (map) {
      map.locked = !map.locked
      this.$store.dispatch('POST_TOGGLE_LOCK_MAP', { id: map.id })
    },
    newAssignment (map) {
      this.map.user = null
      this.map.number = map.number
      this.map.id = map.id
      this.map.type_id = map.type_id
      this.assignFormActive = true
    },
    reAssignment (map) {
      this.map.id = map.id
      this.map.user = map.assignment.user ? map.assignment.user.id : null
      this.map.number = map.number
      this.map.personal = map.assignment.personal ? 1 : 0
      this.map.assignment = map.assignment.id
      this.reAssignFormActive = true
    },
    submitAssignment () {
      if (this.map.type_id === 1) {
        this.submitting = true
      } else {
        this.assignFormActive = false
        this.progressActive = true
        this.progress.percent = 0
        this.getMapAssignmentProgress()
      }
      this.$store.dispatch('ASSIGN_MAP', this.map)
        .then(() => {
          if (this.map.type_id === 1) {
            this.submitting = false
            this.assignFormActive = false
          } else {
            this.progress.percent = 100
            this.progressActive = false
          }
        })
    },
    getMapAssignmentProgress () {
      setTimeout(() => {
        axios.get('api/map/assign/' + this.map.id + '/progress')
          .then((response) => {
            if (response.data.data && response.data.data.total) {
              const total = response.data.data.total
              const completed = response.data.data.completed
              this.progress.total = total
              this.progress.complete = completed
              this.progress.percent = parseInt((100 / total) * completed)
              if (this.progress.percent < 100) this.getMapAssignmentProgress()
              else {
                this.progress.percent = 100
                this.progressActive = false
              }
            }
          })
      }, 3000)
    },
    submitReAssignment () {
      this.submitting = true
      this.$store.dispatch('REASSIGN_MAP', { map: this.map }).then((response) => {
        const key = this.maps.findIndex(row => row.id === this.map.id)
        this.$set(this.maps, key, response.data.data)
        this.submitting = false
        this.reAssignFormActive = false
      })
    },
    promptRecallMap (map) {
      this.map.number = map.number
      this.map.assignment = map.assignment.id
      this.map.id = map.id
      this.mapRecallActive = true
    },
    confirmRecallMap () {
      this.submitting = true
      axios.post('api/map/recall', this.map)
        .then(response => {
          const key = this.maps.findIndex(row => row.id === this.map.id)
          this.$set(this.maps, key, response.data.data)
          this.submitting = false
          this.mapRecallActive = false
        })
        .catch(e => {
          this.$emit('update:error', e)
        })
    },
    promptCancelMap (map) {
      this.map.number = map.number
      this.map.assignment = map.assignment.id
      this.map.id = map.id
      this.mapCancelActive = true
    },
    confirmCancelMap () {
      this.submitting = true
      axios.post('api/map/cancel', this.map)
        .then(response => {
          const key = this.maps.findIndex(row => row.id === this.map.id)
          this.$set(this.maps, key, response.data.data)
          this.submitting = false
          this.mapCancelActive = false
        })
        .catch(e => {
          this.$emit('update:error', e)
        })
    },
    toggleNumbers (map) {
      map.number_lookup = !map.number_lookup
      this.$store.dispatch('POST_TOGGLE_NUMBER_SEARCH', { id: map.id })
    },
    setOrder (column) {
      if (column === this.$store.state.core.order.map_assignments[0]) {
        if (this.$store.state.core.order.map_assignments[1] === 'asc') this.$store.state.core.order.map_assignments = [column, 'desc']
        else this.$store.state.core.order.map_assignments = [column, 'asc']
      } else {
        this.$store.state.core.order.map_assignments = [column, 'asc']
      }
    },
    setFilter (name, value) {
      const key = this.$store.state.core.filters.map_assignments.findIndex(row => row.name === name)
      if (key >= 0) {
        this.$delete(this.$store.state.core.filters.map_assignments, key)
      } else {
        this.$store.state.core.filters.map_assignments.unshift({ name: name, value: value })
      }
    },
    ago (time) {
      return (new Sec(time).ago())
    }
  }
}
</script>

<style lang="less">

.control {

  .button {

    span {
      margin-left: 8px;
    }
  }
}
</style>
