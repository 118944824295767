<template>

    <view-content :back="{ name:'Schedules'} ">

      <template v-slot:header>
        {{ $t('labels.service.label') }}
      </template>

      <template v-slot:subtext>
        {{ $t('labels.service.subtext') }}
      </template>

      <card v-for="meeting in meetings"
            :key="'meeting' + meeting.id"
            :start="meeting.meeting_date"
            :date="true" :color="'meeting'"
            class="mb-5"
            :actionSlot="false"
            :location="meeting.location"
            :rendering="false"
            :time="meeting.time"
      >
        <template v-slot:header>
          {{ meeting.label }}
        </template>
        <template v-slot:content>
          <div class="-ml-2 mt-2">
          <card-summary>
            <template v-slot:heading>{{ $tc('resources.overseer', 1) }}</template>
            <template v-slot:content>
              {{ meeting.overseer.full_name }} - <a class="text-gray-500" v-if="meeting.overseer.phone" :href="'tel:'+meeting.overseer.phone">{{ meeting.overseer.phone }}</a>
            </template>
          </card-summary>
          </div>
        </template>
      </card>

    </view-content>

</template>

<script setup>
import { computed, onMounted } from 'vue'
import { store } from '@/store'

const meetings = computed(() => store.state.schedules.field_meetings)

onMounted(() => {
  store.commit('BACKGROUND_LOADING', true)
  store.dispatch('GET_FIELD_MEETINGS').then(() => {
    store.commit('BACKGROUND_LOADING', false)
  })
})
</script>

<style lang="less">

    .table {
        margin-bottom: 0;
    }

</style>
