import { defineStore } from 'pinia'
import { useCollectionsStore } from '@/stores/collections'
import { filter, find, flatMap, map, uniq } from 'lodash'
import { i18n } from '@/i18n'
import { Sec } from '@/mixins/sec'

export const useMeetingsStore = defineStore({
  id: 'meetings',
  state: () => ({
    activeYear: new Sec().subtract(1, 'd').startOf('week').format('Y'),
    activeMonth: parseInt(new Sec().subtract(1, 'd').startOf('week').month()),
    activeDay: new Sec().subtract(1, 'd').startOf('week').add(1, 'd').date()
  }),
  getters: {
    allMeetings () {
      const store = useCollectionsStore()
      return store.all('meetings')
    },
    meetingsForYear () {
      return filter(this.allMeetings, m => m.year === this.activeYear)
    },
    meetingsForMonth () {
      return filter(this.meetingsForYear, m => (parseInt(m.month) - 1) === this.activeMonth)
    },
    publicMeeting () {
      return find(this.meetingsForMonth, m => m.start_date === this.activeStartDate && m.type === 'public')
    },
    clamMeeting () {
      return find(this.meetingsForMonth, m => m.start_date === this.activeStartDate && m.type === 'clam')
    },
    allParts () {
      const store = useCollectionsStore()
      return store.all('meetingParts')
    },
    meetingPartsForYear () {
      const meetingIds = map(this.meetingsForYear, m => m.edited_id ?? m.id)
      return filter(this.allParts, p => meetingIds.includes(p.meeting_id))
    },
    meetingPartsForMonth () {
      const meetingIds = map(this.meetingsForMonth, m => m.edited_id ?? m.id)
      return filter(this.meetingPartsForYear, p => meetingIds.includes(p.meeting_id))
    },
    allAssignments () {
      const store = useCollectionsStore()
      return store.all('meetingAssignments')
    },
    meetingAssignmentsForYear () {
      const meetingIds = map(this.meetingsForYear, m => m.edited_id ?? m.id)
      return filter(this.allAssignments, p => meetingIds.includes(p.meeting_id))
    },
    meetingAssignmentsForMonth () {
      const meetingIds = map(this.meetingsForMonth, m => m.edited_id ?? m.id)
      return filter(this.meetingAssignmentsForYear, p => meetingIds.includes(p.meeting_id))
    },
    years () {
      return uniq(flatMap(this.allMeetings, m => m.year)).reduce((o, y) => { o[y] = y; return o }, {})
    },
    months () {
      const arr = []
      for (let m = 0; m < 12; m++) {
        arr.push(i18n.t('time.month.' + m))
      }
      return arr
    },
    days () {
      const days = {}
      const collection = filter(this.meetingsForMonth, (r) => {
        return r.type === 'public'
      })
      collection.forEach(m => {
        const date = new Sec(m.start_date)
        days[date.date()] = date.date() + i18n.t('time.dateSuffix.' + date.date())
      })
      return days
    },
    activeStartDate () {
      const start = new Sec().startOf('month')
      start.year(this.activeYear)
      start.month(this.activeMonth)
      start.date(this.activeDay)

      return start.format('Y-m-d')
    }
  },
  actions: {
    //
  }
})
