import { defineStore } from 'pinia'
import { useCollectionsStore } from './collections'
import { remove } from 'lodash'
import axios from 'axios'
import { store } from '@/store'
import { useRoute, useRouter } from 'vue-router/composables'
import { i18n, requestLanguage } from '@/i18n'
import { get } from '@/composables/serverInterface'

export const useProfileStore = defineStore({
  id: 'profile',
  persist: true,
  state: () => ({
    user: {},
    globalLoad: null,
    darkMode: false,
    token: null,
    authenticated: false,
    lastAuthenticated: null,
    permits: {},
    cache: {}, // Move to collection and then remove
    authClass: 'in',
    appLoading: false,
    appOnline: true,
    offlinePayloads: []
  }),
  getters: {},
  actions: {
    can (permission) {
      return this.permits[permission]
    },
    checkAuthentication () {
      const route = useRoute()
      const router = useRouter()
      const newStore = useCollectionsStore()

      // Temporary measure to move token across to being stored in profile.
      // Remove after a little while to just use profile token
      if (route.query.t) this.token = route.query.t
      else if (store.state.core.token) this.token = store.state.core.token

      axios.get('/api/auth?api_token=' + this.token)
        .then(res => {
          store.commit('RESET_LOAD_REQUESTS')
          if (res.data.data.authorised === true) {
            i18n.locale = res.data.data.lang
            if (res.data.data.lang !== 'en') {
              requestLanguage(res.data.data.lang)
            }
            this.authClass = 'out'
            store.commit('AUTHORISED', res.data.data)

            store.commit('ASSIGN_PERMITS', res.data.data.permissions)
            this.setState('permits', res.data.data.permissions)

            store.commit('UPDATE_REMOTE_CACHE', res.data.data.cache)
            this.setState('cache', res.data.data.cache)
            newStore.setState('cache', res.data.data.cache)

            console.log('Preload')
            this.preload().then(() => {
              console.log('Preload Finished')
            })
            this.sync()

            setTimeout(() => {
              this.setState('lastAuthenticated', new Date())
              this.appLoading = true
              this.welcomeClass = 'in'
              if (store.state.core.temp_url) {
                const url = store.state.core.temp_url
                console.log('Temp URL: '.url)
                store.state.core.temp_url = null
                router.push(url)
              } else if (store.state.core.tempTo) {
                const to = store.state.core.tempTo
                if (to.query.src) delete (to.query.src)
                router.push({ name: to.name, query: to.query })
                store.state.core.tempTo = null
              } else {
                router.push('/?t=' + store.state.core.token)
              }
            }, 750)
            setTimeout(() => {
              store.dispatch('GET_CONG_SETTINGS').then((res) => {
                if (res.data.data.active) {
                  store.state.core.singleView = false
                }
                setTimeout(() => {
                  this.appLoading = false
                }, 750)
                store.dispatch('LOAD_APP_DATA', { token: store.state.core.token }).then(() => {
                  store.dispatch('GET_LANGUAGES')
                  store.dispatch('GET_ADDRESS_OPTIONS')
                  store.dispatch('GET_ASSIGNMENTS')
                  get(['parts'])
                  get(['groups'])
                  console.log('finished')
                  this.welcomeClass = 'out'
                }, (error) => {
                  console.log(error)
                  this.appLoading = false
                })
              }, (error) => {
                console.log(error)
                this.appLoading = false
              })
            }, 0)
          } else {
            store.state.core.deniedReason = res.data.data.reason
            store.commit('UNAUTHORISED')
            router.push('/denied')
          }
        })
        .catch(e => {
          this.error = e
        })
    },
    preload () {
      const store = useCollectionsStore()
      const promises = []
      promises.push(store.get('users'))
      promises.push(store.get('sharedCarts'))

      return Promise.all(promises)
    },
    authenticate () {
      const newStore = useCollectionsStore()

      return new Promise((resolve, reject) => {
        axios.get('/api/auth?api_token=' + this.token)
          .then(res => {
            newStore.cache = res.data.data.cache
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    sync () {
      const store = useCollectionsStore()

      const promises = []
      promises.push(store.get('roles'))
      promises.push(store.get('permissions'))
      promises.push(store.get('countries'))
      promises.push(store.get('regions'))
      promises.push(store.get('meetings'))
      promises.push(store.get('meetingParts'))
      promises.push(store.get('meetingAssignments'))
      promises.push(store.get('meetingAttendance'))
      // promises.push(store.get('users'))

      for (const payload of this.offlinePayloads) {
        promises.push(
          store.post(payload.uri, payload.payload)
            .then(() => {
              console.log(payload.id)
              remove(this.offlinePayloads, (p) => p.id === payload.id)
            })
            .catch(err => {
              console.log(err)
            })
        )
      }

      return Promise.all(promises)
    }
  }
})
