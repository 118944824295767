<template>
  <div>
    <modal :header="resource.id ? labels.edit : labels.create" :active="active" @submit="submit" @close="emit('close')" :validated="userValid">
      <template v-slot:default="{ error }">
        <tuxedo-input :name="'first_name'" :label="$t('fields.first_name.label')" :sub="$t('labels.required')" v-model="resource.first_name" :error="error" :inputClass="'bg-gray-100'" />
        <tuxedo-input :name="'last_name'" :label="$t('fields.last_name.label')" :sub="$t('labels.required')" v-model="resource.last_name" :error="error" :inputClass="'bg-gray-100'" />
        <tuxedo-input :name="'email'" :label="$t('fields.email.label')" :type="'email'" v-model="resource.email" :error="error" :inputClass="'bg-gray-100'" />
        <tuxedo-input :name="'phone'" :label="$t('fields.mobile.label')" v-model="resource.phone" :error="error" :inputClass="'bg-gray-100'" />
        <tuxedo-select :name="'roles'" :label="$t('fields.role.label')" :sub="$t('labels.required')" v-model="resource.roles" :options="roles" :insistLabel="'name'" :insistReturn="'id'" :multi="true" :error="error" :inputClass="'bg-gray-100'" />
        <tuxedo-select :name="'lang'" :label="$t('fields.language.label')" v-model="resource.lang" :options="langs" :insistLabel="'lang'" :insistReturn="'iso'" :error="error" :inputClass="'bg-gray-100'" />
        <tuxedo-select v-if="resource.appointment !== 'guest'" :name="'group_id'" :label="$t('fields.group.label')" v-model="resource.group_id" :options="groups" :insistLabel="'name'" :insistReturn="'id'" :error="error" :inputClass="'bg-gray-100'" />
        <input-text v-if="resource.appointment !== 'guest'" :label="$t('fields.address.label')" :name="'address'" v-model="resource.address" />
        <input-message v-if="resource.appointment !== 'guest'" :label="$t('fields.emergency.label')" :name="'emergency'" v-model="resource.emergency" />
        <tuxedo-date v-if="resource.appointment !== 'guest'" :label="'Date of Birth'" :name="'dob'" :insistFormat="'isoDate'" v-model="resource.dob" :help="'Used for the s-21 form'" :error="error" :inputClass="'bg-gray-100'"></tuxedo-date>
        <tuxedo-date v-if="resource.appointment !== 'guest'" :label="'Date Immersed'" :name="'doi'" :insistFormat="'isoDate'" v-model="resource.doi" :help="'Used for the s-21 form'" :error="error" :inputClass="'bg-gray-100'"></tuxedo-date>
        <tuxedo-radio :name="'gender'" :label="$t('fields.appointment.label')" v-model="resource.gender" :options="{1:'Brother',2:'Sister'}" :required="true" :merge="true" />
        <tuxedo-radio :name="'appointment'" v-model="resource.appointment" :options="{ 'guest': 'Guest', 'student': 'Student', 'unbaptised': 'Unbaptised' }" :merge="true" />
        <tuxedo-radio v-if="resource.gender == 2" :name="'appointment'" v-model="resource.appointment" :options="{ 'inactive': 'Inactive', 'publisher': 'Publisher' }" :merge="true" :error="error" />
        <tuxedo-radio v-if="resource.gender == 1" :name="'appointment'" v-model="resource.appointment" :options="{ 'inactive': 'Inactive', 'publisher': 'Publisher', 'elder': 'Elder', 'servant': 'Servant' }" :merge="true" :error="error" />
        <tuxedo-radio :name="'hope'" v-model="resource.hope" :options="{0: 'Other Sheep',1:'Anointed'}"></tuxedo-radio>
        <input-toggle class="mb-3" v-if="resource.appointment !== 'guest'" :label="$t('fields.pioneer.label')" v-model="resource.pioneer" :options="{0:'No',1:'Yes'}" />
        <input-toggle class="mb-3" v-if="resource.appointment !== 'guest'" :label="$t('fields.special_pioneer.label')" v-model="resource.special_pioneer" :options="{0:'No',1:'Yes'}" />
        <input-toggle class="mb-3" :label="$t('fields.cart_approved.label')" v-model="resource.cart" :options="{0:'No',1:'Yes'}" />
        <input-toggle :label="$t('fields.active.label')" v-model="resource.active" :options="{0:'No',1:'Yes'}" />
      </template>
    </modal>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import { put, post } from '@/composables/serverInterface.js'
import { useLabels } from '@/composables/labels'
import { store } from '@/store'
import { useCollectionsStore } from '@/stores/collections'

const labels = useLabels('users')
const newStore = useCollectionsStore()
const props = defineProps(['user', 'active'])
const emit = defineEmits(['close'])

const initialResource = {
  id: 0,
  first_name: null,
  last_name: null,
  email: '',
  phone: '',
  lang: null,
  roles: null,
  appointment: null,
  gender: null,
  pioneer: null,
  special_pioneer: null,
  cart: null,
  group_id: null,
  address: null,
  emergency: null,
  active: null,
  dob: null,
  doi: null,
  hope: null
}
const resource = ref({ ...initialResource })
const langs = store.state.core.langs
const groups = store.state.groups
const roles = newStore.all('roles')

watch(() => props.active, async (active) => {
  if (active === true) {
    resource.value = { ...initialResource }
    if (props.user) {
      Object.keys(resource.value).forEach(key => {
        if (props.user[key]) resource.value[key] = props.user[key]
      })
    }
  }
})

watch(() => resource.value.pioneer, val => {
  if (val === 1) resource.value.special_pioneer = 0
})

watch(() => resource.value.special_pioneer, val => {
  if (val === 1) resource.value.pioneer = 0
})

const userValid = computed(() => {
  return !!(resource.value.first_name &&
    resource.value.last_name &&
    resource.value.gender)
})

const submit = () => {
  if (resource.value.id) {
    put(['users', resource.value.id], resource.value).then(res => {
      newStore.merge('users', res)
      emit('close', res)
    })
  } else {
    post(['users'], resource.value).then(res => {
      newStore.merge('users', res)
      emit('close', res)
    })
  }
}

</script>
