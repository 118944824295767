<template>
  <div>
    <modal :header="'Assign'" @submit="submit" :active="active" @close="emit('update:active', false)">
      <div class="display-flex mb-2">
        <action :secondary="true" :small="true"
                @click="orderMenu = true"
                :id="'orderActionPart' + part.id"
                class="mr-2 mb-2" :icon="['fal', 'arrow-alt-down']">
          <span>Sort By</span>
        </action>
        <action :secondary="true" :small="true"
                @click="filterMenu = true"
                :id="'filterActionPart' + part.id"
                class="mr-2 mb-2" :icon="['fal', 'filter']">
          <span>Filter</span>
        </action>
        <tuxedo-secondary-menu v-if="orderMenu" @close="orderMenu = false" :target="'orderActionPart' + part.id">
          <ul class="text-lg m-4">
            <sm-item class="justify-content-center" @click="reorderPubs('last_name','asc')">Name (A-Z)</sm-item>
            <sm-item class="justify-content-center" @click="reorderPubs('last_name','desc')">Name (Z-A)</sm-item>
            <sm-item class="justify-content-center" @click="reorderPubs('part','desc')">Time since last giving this part</sm-item>
          </ul>
        </tuxedo-secondary-menu>
        <tuxedo-secondary-menu v-if="filterMenu" @close="filterMenu = false" :target="'filterActionPart' + part.id">
          <ul class="text-lg m-4">
            <sm-item class="justify-content-center" @click="filterPubs([1],[], [])">Show only Brothers</sm-item>
            <sm-item class="justify-content-center" @click="filterPubs([2],[], [])">Show only Sisters</sm-item>
            <sm-item class="justify-content-center" @click="filterPubs([1],['elder', 'servant'], [])">Show only Appointed Brothers</sm-item>
            <sm-item class="justify-content-center" @click="filterPubs([1],['student', 'unbaptised', 'publisher'], [])">Show only non-Appointed Brothers</sm-item>
            <sm-item class="justify-content-center" @click="filterPubs([],['student', 'unbaptised'], [])">Show only Students and Unbaptised Pubs</sm-item>
            <sm-item v-for="group in groups"
                     :key="group.id"
                     class="justify-content-center"
                     @click="filterPubs([],[], [group.id])">
              <div>Show only {{ group.name }} Pubs</div>
              <div class="text-xs">{{ find(newStore.all('users'), ['id', group.overseer_id]).last_first }}</div>
            </sm-item>
          </ul>
        </tuxedo-secondary-menu>
      </div>
      <div class="">

        <TransitionGroup name="collapse">
          <div v-for="user in orderedPubs" :key="assignKey() + user.id" v-show="showUser(user)">
            <label class="block p-2 rounded text-left mb-3 font-sans text-gray-800 shadow-md border-0 border-l-4" :class="userClass(user)">
              <input class="hidden" type="radio" @click="checkUser(user.id)" :value="user.id" :checked="selectedUser === user.id">
              <span class="block mb-1 font-semibold">
                {{ user.last_first }} <b v-if="user.away"> - {{ $t('labels.away') }}</b><b v-if="user.partThisWeek"> - {{ $t('messages.has_part_this_week') }}</b>
              </span>
              <span class="grid gap-1" style="font-size:12px;line-height:16px" v-if="!calculatingPartProfile && user.lastParts">
                <span v-for="ass in user.lastParts.slice(0, 4)" :key="assignKey() + ass.id">
                  <span class="" :class="partRef(part, hall) === ass.partRef ? 'font-semibold' : ''">
                    {{
                      $tc('messages.since_last_assignment.script', ass.diff, {
                        weeks: ass.diff,
                        part: $t('messages.since_last_assignment.' + ass.partRef)
                      })
                    }}
                  </span>
                </span>
                <span v-if="false">
                  {{ $tc('messages.parts_scheduled', user.partsInMonth, { count: user.partsInMonth }) }}
                </span>
              </span>
              <span v-else class="grid gap-2 pt-1">
                <span v-for="n in 5" :key="n">
                  <span class="block rounded-md bg-gray-200 w-100 h-3"></span>
                </span>
              </span>
              <span class="grid gap-1 grid-flow-col mt-1">
                <span v-for="n in 13" :key="n" class="grid gap-1 justify-center rounded py-2" :class="n === 9 ? 'border' : ''">
                  <span class="h-3 w-3 sm:w-6 rounded" v-if="meetingType === 'public'" :class="find(user.partsInRange, p => p.diff === (n - 9) * -1 && p.meeting === 'talk') ? 'bg-talk' : 'bg-gray-100'"></span>
                  <span class="h-3 w-3 sm:w-6 rounded" v-if="meetingType === 'public'" :class="find(user.partsInRange, p => p.diff === (n - 9) * -1 && p.meeting === 'watchtower') ? 'bg-watchtower' : 'bg-gray-100'"></span>
                  <span class="h-3 w-3 sm:w-6 rounded" v-if="meetingType === 'clam'" :class="find(user.partsInRange, p => p.diff === (n - 9) * -1 && p.meeting === 'prelude') ? 'bg-prelude' : 'bg-gray-100'"></span>
                  <span class="h-3 w-3 sm:w-6 rounded" v-if="meetingType === 'clam'" :class="find(user.partsInRange, p => p.diff === (n - 9) * -1 && p.meeting === 'treasures') ? 'bg-treasures' : 'bg-gray-100'"></span>
                  <span class="h-3 w-3 sm:w-6 rounded" v-if="meetingType === 'clam'" :class="find(user.partsInRange, p => p.diff === (n - 9) * -1 && p.meeting === 'ministry') ? 'bg-ministry' : 'bg-gray-100'"></span>
                  <span class="h-3 w-3 sm:w-6 rounded" v-if="meetingType === 'clam'" :class="find(user.partsInRange, p => p.diff === (n - 9) * -1 && p.meeting === 'living') ? 'bg-living' : 'bg-gray-100'"></span>
                </span>
              </span>
            </label>
          </div>
        </TransitionGroup>

      </div>
    </modal>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { chunk, filter, find, orderBy, remove } from 'lodash'
import { merge } from '@/helpers'
import { store } from '@/store.js'
import { useCollectionsStore } from '@/stores/collections'
import { useAPIStore } from '@/stores/api'
import { assignKey } from '@/composables/assignKey'
import { Sec } from '@/mixins/sec'
// eslint-disable-next-line import/no-webpack-loader-syntax
import BuildUserAssignmentsProfile from 'worker-loader!@/workers/buildUserAssignmentsProfile.js'

const props = defineProps(['active', 'part', 'hall', 'assignment', 'assignmentType'])
const emit = defineEmits(['input'])

const newStore = useCollectionsStore()
const api = useAPIStore()
const sort = ref('part')
const direction = ref('asc')
const filterGender = ref([])
const filterAppointment = ref([])
const filterGroup = ref([])
const orderMenu = ref(false)
const filterMenu = ref(false)
const selectedUser = ref(false)
const calculatingPartProfile = ref(false)
const assignmentWeek = ref(null)
const approvedPubs = ref([])

const groups = store.state.groups

const filteredPubs = computed(() => {
  console.log('filteredPubs Eval: ' + new Sec().timestamp())
  return filter(approvedPubs.value, u => {
    if (filterGender.value && filterGender.value.length > 0) {
      if (!filterGender.value.includes(u.gender)) return false
    }
    if (filterAppointment.value && filterAppointment.value.length > 0) {
      if (!filterAppointment.value.includes(u.appointment)) return false
    }
    if (filterGroup.value && filterGroup.value.length > 0) {
      if (!filterGroup.value.includes(u.group_id)) return false
    }
    return true
  })
})

const orderedPubs = computed(() => {
  console.log('orderPubs Eval: ' + new Sec().timestamp())
  if (sort.value === 'part') {
    return orderBy(filteredPubs.value, p => {
      if (props.helper && p.lastAsHelper) return p.lastAsHelper
      if (p.lastOfKind) return p.lastOfKind
      else return '2019-01-01'
    })
  } else {
    return orderBy(filteredPubs.value, ['last_name'], [direction.value])
  }
})

const meetingType = computed(() => {
  if (['talk', 'watchtower'].includes(props.part.meeting)) return 'public'
  return 'clam'
})

watch(() => props.active, () => {
  if (props.active) {
    approvedPubs.value = filter(newStore.all('users'), u => {
      return find(u.qualified_parts, q => {
        if (props.part.user_meeting_part_id === 2 && props.hall > 1) return q.id === 17
        else return q.id === (props.assignmentType === 'helper' ? props.part.helper_meeting_part_id : props.part.user_meeting_part_id)
      })
    })

    selectedUser.value = false
    assignmentWeek.value = new Sec(props.part.startTime).hour(10).minute(0).dayOfWeek(1)
    userLastAssignment()
    filterPubs([], [])
    if (props.assignment) {
      selectedUser.value = props.assignmentType === 'main' ? props.assignment.user_id : props.assignment.helper_id
    }
  }
})

const partRef = (part, hall, helper = false) => {
  const m = typeof part === 'object' ? part.meeting : part.split('.')[0]
  if (part.user_meeting_part_id === 1) return 'prayer'
  if (m === 'ministry' && (helper || props.assignmentType === 'helper')) return 'ministry_helper'
  if (m === 'ministry' && part.user_meeting_part_id === 9) return 'ministry_talk'
  if (m === 'ministry' && part.user_meeting_part_id === 8) return 'ministry_study'
  if (m === 'ministry') return 'ministry_demonstration'
  const partId = typeof part === 'object' ? part.part : part.split('.')[1]
  let helperType = null
  if (helper) helperType = helper
  else if (props.assignmentType === 'helper') {
    helperType = typeof part === 'object' ? part.helper_type : part.split('.')[2]
  }
  return m + '_' + partId + (helperType ? '_' + helperType : '') + (partId === 'comments' && hall === 1 ? 1 : '')
}

const showUser = (user) => {
  return selectedUser.value === user.id || !selectedUser.value || !find(approvedPubs.value, p => p.id === selectedUser.value)
}

const userClass = (user) => {
  if (user.away || user.partThisWeek) return 'border-red-800 bg-red-200'
  if (user.id === selectedUser.value) return 'border-orange bg-orange-200'
  return 'bg-white'
}

const checkUser = (id) => {
  selectedUser.value = selectedUser.value === id ? false : id
}

const reorderPubs = (s, d) => {
  sort.value = s
  direction.value = d
  orderMenu.value = false
}

const filterPubs = (gender, appointment, group) => {
  filterGender.value = gender
  filterAppointment.value = appointment
  filterGroup.value = group
  filterMenu.value = false
}

const userLastAssignment = () => {
  calculatingPartProfile.value = true
  const adjustedPubs = []
  const chunks = chunk(approvedPubs.value, 20)
  console.log('Chunks: ' + chunks.length)
  const workerPool = [
    new BuildUserAssignmentsProfile(),
    new BuildUserAssignmentsProfile(),
    new BuildUserAssignmentsProfile()
  ]

  // Start out chunkIndex at the index number of workers in the pool
  let chunkIndex = 2

  const handleWorkerMessage = (worker, workerId, event) => {
    merge(adjustedPubs, event.data)
    chunkIndex++

    if (chunkIndex < chunks.length) {
      worker.postMessage({
        workerId: workerId,
        pubs: chunks[chunkIndex],
        assignments: newStore.all('meetingAssignments'),
        meetingType: meetingType.value,
        part: props.part,
        hall: props.hall,
        assignmentType: props.assignmentType
      })
    } else if ((chunkIndex - 2) >= chunks.length) {
      console.log('calculted for ' + adjustedPubs.length + ' pubs')
      newStore.merge('users', adjustedPubs)
      calculatingPartProfile.value = false
      // approvedPubs.value = adjustedPubs
      adjustedPubs.forEach(o => {
        remove(approvedPubs.value, i => i.id === o.id)
        approvedPubs.value.push(o)
      })
    }
  }

  // Distribute work across workers
  workerPool.forEach((worker, i) => {
    worker.addEventListener('message', (event) => handleWorkerMessage(worker, i, event))
    if (i < chunks.length) {
      worker.postMessage({
        workerId: i,
        pubs: chunks[i],
        assignments: newStore.all('meetingAssignments'),
        meetingType: meetingType.value,
        part: props.part,
        hall: props.hall,
        assignmentType: props.assignmentType
      })
    }
  })
}

const submit = () => {
  const resource = {
    id: props.assignment ? props.assignment.id : null,
    user_id: props.assignmentType === 'main' ? selectedUser.value : props.assignment ? props.assignment.user_id : null,
    helper_id: props.assignmentType === 'helper' ? selectedUser.value : props.assignment ? props.assignment.helper_id : null,
    hall: props.hall
  }

  const uri = ['meetingParts', props.part.id, 'assignments']
  if (resource.id) {
    uri.push(resource.id)
    api.put(uri, resource).then(res => {
      newStore.merge('meetingAssignments', res)
      emit('update:active', false)
    })
  } else {
    api.post(uri, resource).then(res => {
      newStore.merge('meetingAssignments', res)
      emit('update:active', false)
    })
  }
}

</script>
