export const state = {
  attendantResponsibilities: [],
  availableAddresses: [],
  awayTalks: [],
  axiosQueue: [],
  axiosTiming: [],
  timezones: {},
  cartBlocks: {},
  cartLocations: [],
  cartRotations: {},
  cartSchedules: {},
  congregations: [],
  doNotCalls: [],
  dncs: [],
  events: [],
  fieldServiceGroups: [],
  fieldServiceReports: {},
  fieldServiceReportsStore: [],
  fieldServiceReportsState: {
    tableRows: 500,
    page: 1,
    sort: {
      col: 'user_id',
      dir: 'asc'
    }
  },
  foreign: [],
  forum: {},
  forums: [],
  forumsState: {
    tableRows: 200,
    page: 1,
    sort: {
      col: 'label',
      dir: 'desc'
    }
  },
  forumTopics: [],
  forumTopicsStore: [],
  forumTopicsState: {
    lastScrollPosition: [],
    tableRows: 200,
    page: 1,
    sort: {
      col: 'likes_count',
      dir: 'desc'
    }
  },
  groups: {},
  groupsStore: [],
  groupsState: {
    lastScrollPosition: [],
    tableRows: 200,
    page: 1,
    sort: {
      col: 'name',
      dir: 'desc'
    }
  },
  mapAssignments: [],
  meetings: [],
  meetingParts: [],
  meetingYears: [],
  parts: [],
  partsState: {
    tableRows: 1000,
    page: 1,
    sort: {
      col: 'id',
      dir: 'asc'
    }
  },
  preloadedAddresses: [],
  publicMeetings: [],
  publicTalkTitles: [],
  rotationGroups: [],
  scrollPosition: {},
  specialServiceGroups: [],
  streets: [],
  suburbs: [],
  topic: {},
  topicComments: {}
}
