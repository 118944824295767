<template>

  <view-content :back="{ name:'Settings' }">

    <template v-slot:header>
      Field Service Reports
    </template>

    <collapse accordion is-fullwidth v-if="collectionsStore.users.length > 1">
      <collapse-item title="Order & Filter">
        <div class="field has-addons">
          <p class="control">
            <button class="button" :class="{'is-info':$store.state.core.order.reports[0] === 'appointment'}" @click="setOrder('appointment')">
              <fa-icon :icon="['fal', 'arrow-alt-down']" v-if="($store.state.core.order.reports[0] === 'appointment' && $store.state.core.order.reports[1] === 'asc') || $store.state.core.order.reports[0] !== 'appointment'" />
              <fa-icon :icon="['fal', 'arrow-alt-up']" v-if="$store.state.core.order.reports[0] === 'appointment' && $store.state.core.order.reports[1] === 'desc'" />
              <span>Appointment</span>
            </button>
          </p>
          <p class="control">
            <button class="button" :class="{'is-info':$store.state.core.order.reports[0] === 'group_name'}" @click="setOrder('group_name')">
              <fa-icon :icon="['fal', 'arrow-alt-down']" v-if="($store.state.core.order.reports[0] === 'group_name' && $store.state.core.order.reports[1] === 'asc') || $store.state.core.order.reports[0] !== 'group_name'" />
              <fa-icon :icon="['fal', 'arrow-alt-up']" v-if="$store.state.core.order.reports[0] === 'group_name' && $store.state.core.order.reports[1] === 'desc'" />
              <span>Group</span>
            </button>
          </p>
          <p class="control">
            <button class="button" :class="{'is-info':$store.state.core.order.reports[0] === 'last_first'}" @click="setOrder('last_first')">
              <fa-icon :icon="['fal', 'arrow-alt-down']" v-if="($store.state.core.order.reports[0] === 'last_first' && $store.state.core.order.reports[1] === 'asc') || $store.state.core.order.reports[0] !== 'first_name'" />
              <fa-icon :icon="['fal', 'arrow-alt-up']" v-if="$store.state.core.order.reports[0] === 'last_first' && $store.state.core.order.reports[1] === 'desc'" />
              <span>Name</span>
            </button>
          </p>
        </div>
        <div class="field has-addons">
          <p class="control">
            <button class="button" :class="{'is-info':$store.state.core.filters.reports.findIndex(row => row.name === 'current_report') >= 0 }" @click="setFilter('current_report',true)">
              <fa-icon :icon="['fal', 'filter']" />
              <span>Pending</span>
            </button>
          </p>
          <p class="control">
            <button class="button" :class="{'is-info':$store.state.core.filters.reports.findIndex(row => row.name === 'group_id') >= 0 }" @click="setFilter('group_id',profile.group_id)">
              <fa-icon :icon="['fal', 'filter']" />
              <span>My Group</span>
            </button>
          </p>
          <p class="control">
            <button class="button" :class="{'is-info':$store.state.core.filters.reports.findIndex(row => row.name === 'pioneer') >= 0 }" @click="setFilter('pioneer',1)">
              <fa-icon :icon="['fal', 'filter']" />
              <span>Pioneers</span>
            </button>
          </p>
        </div>
      </collapse-item>
    </collapse>

    <div class="card is-blue" v-for="user in orderedUsers" :key="'user' + user.id">
      <div class="card-content">
        <table class="table is-narrow is-fullwidth no-border">
          <tr>
            <th width="60">Name:</th>
            <td>{{ user.last_first }}</td>
          </tr>
          <tr>
            <th>Group:</th>
            <td>{{ user.group_name }}</td>
          </tr>
          <tr v-if="user.current_report">
            <th colspan="2">{{ monthOption[5].month }} Report</th>
          </tr>
          <tr v-if="user.current_report">
            <td colspan="2">
              <table class="table is-fullwidth" style="margin: 0">
                <tr>
                  <th>Std.</th>
                  <th v-if="user.pioneer || user.special_pioneer || (user.current_report && user.current_report.aux)">Hrs.</th>
                  <td rowspan="2" class="tools t1" style="vertical-align:middle">
                    <button @click="editReport(user)" class="btn btn-info btn-round">
                      <fa-icon :icon="['fal', 'pencil']" />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>{{ user.current_report.studies ? user.current_report.studies : 0 }}</td>
                  <td v-if="user.pioneer || user.special_pioneer || (user.current_report && user.current_report.aux)">
                    {{ user.current_report.hours ? user.current_report.hours : 0 }}
                    <span v-if="user.current_report.special_hours">
                      ({{user.current_report.special_hours}})
                    </span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
      <div class="card-footer">
        <div class="tools">
          <a v-if="permits['settings.print.s_21']" :href="'https://srvc.app/api/reports/s21/'+user.id+'?api_token='+$store.state.core.token" target="_blank" class="btn btn-sm btn-info">
            <fa-icon :icon="['fal', 'print']" /> s-21
          </a>
          <router-link :to="'/settings/reports/publisher/'+user.id+'/'" class="btn btn-sm btn-info">
            <fa-icon :icon="['fal', 'clipboard-list']" /> View Reports
          </router-link>
          <button class="btn btn-sm btn-success" @click="addReport(user)">
            <fa-icon :icon="['fal', 'clipboard-list']" /> Add Report
          </button>
        </div>
      </div>
    </div>

    <portal to="modals">
      <FieldServiceReportForm
        :active="reportFormActive"
        :user="activeUser"
        @close="close"
      />

      <FieldServiceReportEditForm
        :active="editReportActive"
        :user="activeUser"
        :report="report"
        @close="close"
      />
    </portal>

  </view-content>

</template>

<script>
import { mapStores } from 'pinia'
import { useCollectionsStore } from '@/stores/collections'
import { get } from '@/composables/serverInterface'
import Collapse from 'vue-bulma-collapse/src/Collapse.vue'
import CollapseItem from 'vue-bulma-collapse/src/Item.vue'
import { resetData, fillObject } from '@/mixins/helpers'
import { mapState } from 'vuex'
import { Sec } from '@/mixins/sec'
import FieldServiceReportForm from '@/components/forms/FieldServiceReportForm.vue'
import FieldServiceReportEditForm from '@/components/forms/FieldServiceReportEditForm.vue'
import { findIndex, forEach } from 'lodash'

export default {
  name: 'Reports',
  mixins: [resetData, fillObject],
  data () {
    return {
      report: {
        id: 0,
        user_id: null,
        report_month: 0,
        hours: 0,
        placements: 0,
        videos: 0,
        return_visits: 0,
        studies: 0,
        special_hours: 0,
        aux: 0,
        pioneer: 0,
        special_pioneer: 0,
        comments: ''
      },
      pioneer: 0,
      special_pioneer: 0,
      monthOptions: {},
      activeUser: {},
      reportFormActive: false,
      editReportActive: false,
      submitting: false,
      loading: true,
      error: false,
      users: []
    }
  },
  components: {
    FieldServiceReportEditForm,
    FieldServiceReportForm,
    Collapse,
    CollapseItem
  },
  mounted () {
    this.users = this.collectionsStore.users
    get('field-service-reports')
      .then(res => {
        forEach(this.collectionsStore.users, function (u) { u.current_report = null })
        res.data.data.forEach(r => {
          const index = findIndex(this.users, u => u.id === parseInt(r.user_id))
          this.users[index].current_report = r
        })
      })
  },
  computed: {
    ...mapStores(useCollectionsStore),
    ...mapState({
      profile: state => state.profile,
      permits: state => state.core.permits,
      sid: state => state.core.sid
    }),
    orderedUsers: function () {
      let users = this._.orderBy(this.users, [this.$store.state.core.order.reports[0]], [this.$store.state.core.order.reports[1]])
      if (!this.permits['settings.users']) {
        users = users.filter((record) => {
          if (parseInt(record.id) === parseInt(this.sid)) return record
        })
      } else if (this.$store.state.core.filters.reports.length > 0 && users.length > 1) {
        this.$store.state.core.filters.reports.forEach(filter => {
          users = users.filter(user => {
            if (filter.name === 'pioneer') {
              if (user.pioneer || user.special_pioneer) return user
            } else {
              if (filter.value === true && !user[filter.name]) {
                return user
              } else if (filter.value === user[filter.name]) {
                return user
              }
            }
          })
        })
      }
      users = users.filter(user => {
        if (user.appointment === null) return false
        if (['guest', 'student', 'inactive'].includes(user.appointment)) return false
        if (parseInt(user.active) === 0) return false
        return user
      })
      return users
    },
    monthOption () {
      const date = new Sec()
      date.subtract(6, 'months')
      const options = []
      for (let i = 0; i < 6; i++) {
        options.push({ month: date.format('F, Y'), date: date.format('Y-M-d') })
        date.add(1, 'month')
      }
      return options
    }
  },
  methods: {
    setOrder (column) {
      if (column === this.$store.state.core.order.reports[0]) {
        if (this.$store.state.core.order.reports[1] === 'asc') this.$store.state.core.order.reports = [column, 'desc']
        else this.$store.state.core.order.reports = [column, 'asc']
      } else {
        this.$store.state.core.order.reports = [column, 'asc']
      }
    },
    setFilter (name, value) {
      const key = this.$store.state.core.filters.reports.findIndex(row => row.name === name)
      if (key >= 0) {
        this.$delete(this.$store.state.core.filters.reports, key)
      } else {
        this.$store.state.core.filters.reports.unshift({ name: name, value: value })
      }
    },
    addReport (user) {
      this.activeUser = user
      this.reportFormActive = true
    },
    editReport (user) {
      this.activeUser = user
      this.editReportActive = true
    },
    close (res) {
      this.reportFormActive = false
      this.editReportActive = false
      if (res && res.data.data) {
        const index = findIndex(this.users, u => u.id === parseInt(res.data.data.user_id))
        this.users[index].current_report = res.data.data
      }
    }
  }
}
</script>

<style>
.last-name {
  text-transform: uppercase;
}
</style>
