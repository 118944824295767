<template>
  <div v-if="assignment">
    <section class="assignment cart">
      <card :start="start.time" :end="end.time" :date="true" :time="'range'" :publishers="publishers"
            :color="'cart'" class="mb-5" :actionSlot="true"
      >
        <template v-slot:header>
          {{ $tc('resources.cart_booking', 1) }}
        </template>
        <template v-slot:content>
          {{ location }}
        </template>
        <template v-slot:actions>
            <action :primary="true" :small="true" @click="promptDeleteBooking">{{ $t('actions.cancel') }}</action>
        </template>
      </card>
    </section>

    <delete
      :header="messages.delete"
      :active.sync="bookingDeleteActive"
      :message="messages.delete"
      @submit="confirmDeleteBooking"
    ></delete>

  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useCollectionsStore } from '@/stores/collections'
import { useAPIStore } from '@/stores/api'
import { messages } from '@/labels'
import { Sec } from '../../mixins/sec'
import { store } from '@/store'
import { find } from 'lodash'

const props = defineProps(['assignment'])

const newStore = useCollectionsStore()
const api = useAPIStore()

const cartLocations = computed(() => store.state.cartLocations)

const start = computed(() => {
  return new Sec(props.assignment.start_at)
})
const end = computed(() => {
  return new Sec(props.assignment.start_at).add(props.assignment.minutes, 'm')
})
const location = computed(() => {
  return find(cartLocations.value, l => l.id === props.assignment.cart_location_id).location
})
const publishers = computed(() => {
  const arr = []
  props.assignment.users.forEach(u => {
    arr.push(find(newStore.all('users'), usr => usr.id === u).full_name)
  })
  return arr
})

const bookingDeleteActive = ref(false)

const promptDeleteBooking = () => {
  bookingDeleteActive.value = true
}
const confirmDeleteBooking = () => {
  api.delete(['cart-schedules', props.assignment.id]).then(() => {
    store.dispatch('GET_ASSIGNMENTS')
    bookingDeleteActive.value = false
  })
}

</script>
